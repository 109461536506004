<template>
  <BlockContainer>
    <div class="p-10 md:p-20">
      <h1 class="text-3xl mb-4">News</h1>
      <h2 class="text-2xl mb-4">Press</h2>
      <ul class="mb-4">
        <li class="mb-2" v-for="(article, i) in orderedPress" :key="i">
          <a
            class="text-base hover:underline"
            :href="article.pdf.fields.file.url"
            target="_blank"
            v-text="article.title"
          />
          <p class="text-sm text-gray">
            {{ formatDate(article.date) }}
          </p>
        </li>
      </ul>
      <h2 class="text-2xl mb-4">Campaigns</h2>
      <ul class="mb-4">
        <li class="mb-2" v-for="(article, i) in orderedCampaigns" :key="i">
          <a
            class="text-base hover:underline"
            :href="article.pdf.fields.file.url"
            target="_blank"
            v-text="article.title"
          />
          <p class="text-sm text-gray">
            {{ formatDate(article.date) }}
          </p>
        </li>
      </ul>
      <h2 class="text-2xl mb-4">Events</h2>
      <ul class="mb-4">
        <li class="mb-2" v-for="(article, i) in orderedEvents" :key="i">
          <a
            class="text-base hover:underline"
            :href="article.pdf.fields.file.url"
            target="_blank"
            v-text="article.title"
          />
          <p class="text-sm text-gray">
            {{ formatDate(article.date) }}
          </p>
        </li>
      </ul>
    </div>
  </BlockContainer>
</template>

<script>
import { getNews } from "@io";
import { BlockContainer } from "@c/Block";

export default {
  name: "News",

  components: {
    BlockContainer
  },

  data: () => ({
    data: null
  }),

  computed: {
    orderedPress() {
      if (!this.data) return;
      return [...this.data]
        .filter(article => article.type === "Press")
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    orderedCampaigns() {
      if (!this.data) return;
      return [...this.data]
        .filter(article => article.type === "Campaign")
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    orderedEvents() {
      if (!this.data) return;
      return [...this.data]
        .filter(article => article.type === "Events")
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    }
  },

  async created() {
    this.data = await getNews();
  },

  methods: {
    formatDate(strDate) {
      const date = new Date(strDate);

      const dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "long"
      });

      const [
        { value: month },
        ,
        { value: year }
      ] = dateTimeFormat.formatToParts(date);

      return `${month} ${year}`;
    }
  }
};
</script>
